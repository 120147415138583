import React, { useState } from 'react';
import { TextField, Button, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import axios from "axios";
import './App.css';

function App() {
  //Base URL
  const client = axios.create({
    baseURL: "https://canadaweb.southeastasia.cloudapp.azure.com:7070/scim/v2"
    // baseURL: "http://localhost:5169/scim/v2"
    // baseURL: "https://curtains-stg.gra.gov.sg/scim/v2"
  });

  //Header
  const [accountId, setAccountId] = useState('hMtl57lk0OfisoniWY2pa6HZozfsQcoNABoO5O7KH0RWZ6AJgH');
  const [secret, setSecret] = useState('bYD2w2WX2B27vYIY7aQAgIrdarJsDPZB54CgOvHYyhcinVhW2g');

  //Request
  const [userId, setUserId] = useState('GRA-V3477MA');
  const [updateUserId, setUpdateUserId] = useState('GRA-V3477MA');
  const [updateUserOp, setUpdateUserOp] = useState(true);
  const [delUserId, setDelUserId] = useState('GRA-V3477MA');
  const [groupId, setGroupId] = useState(1);
  const [updateGroupId, setUpdateGroupId] = useState(1);
  const [updateGroupUserId, setUpdateGroupUserId] = useState('GRA-V3477MA');
  const [updateGroupOp, setUpdateGroupOp] = useState('Remove');
  const [delGroupId, setDelGroupId] = useState(1);

  //Event Listener
  const handleUserOp = (event) => {
    setUpdateUserOp(event.target.value);
  };

  const getUserReq = {
    userId: userId,
  };

  const updateUserReq = {
    "schemas": [
      "urn:ietf:params:scim:api:messages:2.0:PatchOp"
    ],
    "Operations": [
      {
        "op": "Replace",
        "path": "active",
        "value": updateUserOp
      }
    ],
    userId: updateUserId,
  };

  const delUserReq = {
    userId: delUserId,
  };

  const getGroupReq = {
    groupId: groupId,
  };

  const updateGroupReq = {
    "schemas": [
      "urn:ietf:params:scim:api:messages:2.0:PatchOp"
    ],
    "Operations": [
      {
        "op": updateGroupOp,
        "path": "members",
        "value": updateGroupUserId
      }
    ],
    groupId: updateGroupId,
  };

  const delGroupReq = {
    groupId: delGroupId,
  };

  const getListReq = {
    filter: "",
    startIndex: 1,
    itemsPerPage: 100,
    ascOrderBy: "",
    descOrderBy: ""
  };

  //Response
  const [user, setUser] = useState('');
  const [userList, setUserList] = useState('');
  const [updUser, setUpdUser] = useState('');
  const [delUser, setDelUser] = useState('');
  const [group, setGroup] = useState('');
  const [groupList, setGroupList] = useState('');
  const [updGroup, setUpdGroup] = useState('');
  const [delGroup, setDelGroup] = useState('');

  //API Call
  const getUser = (id) => {
    setUser('Loading...');

    var route = '/users/info?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        userId: id,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setUser(JSON.stringify(response.data, null, "\t"));
      })
      .catch((error) => {
        setUser(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const getUserList = () => {
    setUserList('Loading...');

    var route = '/users/findbycriteria?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        filter: "",
        startIndex: 1,
        itemsPerPage: 100,
        ascOrderBy: "",
        descOrderBy: ""
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setUserList(JSON.stringify(response.data, null, "\t"));
      })
      .catch((error) => {
        setUserList(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const updateUser = () => {
    setUpdUser('Loading...');

    var route = '/users/update?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        "schemas": [
          "urn:ietf:params:scim:api:messages:2.0:PatchOp"
        ],
        "Operations": [
          {
            "op": "Replace",
            "path": "active",
            "value": updateUserOp
          }
        ],
        userId: updateUserId,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setUpdUser(JSON.stringify(response.data, null, "\t"));
      })
      .catch((error) => {
        setUpdUser(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const deleteUser = (id) => {
    setUser('Loading...');

    var route = '/users/remove?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        userId: id,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setDelUser('Success!');
      })
      .catch((error) => {
        setDelUser(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const getGroup = (id) => {
    setGroup('Loading...');

    var route = '/groups/info?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        groupId: id,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setGroup(JSON.stringify(response.data, null, "\t"));
      })
      .catch((error) => {
        setGroup(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const getGroupList = () => {
    setGroupList('Loading...');

    var route = '/groups/findbycriteria?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        filter: "",
        startIndex: 1,
        itemsPerPage: 100,
        ascOrderBy: "",
        descOrderBy: ""
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setGroupList(JSON.stringify(response.data, null, "\t"));
      })
      .catch((error) => {
        setGroupList(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const updateGroup = () => {
    setUpdGroup('Loading...');

    var route = '/groups/update?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        "schemas": [
          "urn:ietf:params:scim:api:messages:2.0:PatchOp"
        ],
        "Operations": [
          {
            "op": updateGroupOp,
            "path": "members",
            "value": updateGroupUserId
          }
        ],
        groupId: updateGroupId,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setUpdGroup('Success!');
      })
      .catch((error) => {
        setUpdGroup(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const deleteGroup = (id) => {
    setDelGroup('Loading...');

    var route = '/groups/remove?accountid=' + accountId + '&nonce=09832472134&ts=' + Date.now();

    client
      .post(route, {
        groupId: id,
      }, { headers: { "Authorization": `Bearer ${generateToken(route)}` } })
      .then((response) => {
        setDelGroup('Success!');
      })
      .catch((error) => {
        setDelGroup(JSON.stringify(error.response.data, null, "\t"));
      });
  };

  const generateToken = (route) => {
    var msg = "POST&" + client.defaults.baseURL + route;
    return Base64.stringify(hmacSHA256(msg, secret));
  }

  //UI
  return (
    <div className="App">
      <TextField style={{ maxWidth: '32rem' }} value={accountId} label="Account ID" variant="standard" fullWidth onChange={(e) => {
        setAccountId(e.target.value);
      }} />
      <TextField style={{ maxWidth: '32rem', marginLeft: '1rem' }} value={secret} label="Secret" variant="standard" fullWidth onChange={(e) => {
        setSecret(e.target.value);
      }} />
      <h2>Get User:</h2>
      <div className='item'>
        <TextField value={userId} onChange={(e) => {
          setUserId(e.target.value);
        }} label="User ID" variant="standard" />
        <Button style={{ marginLeft: '1rem' }} variant="contained" onClick={() => getUser(userId)}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(getUserReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{user}</p>
      <Divider />
      <h2>Get User List:</h2>
      <div className='item'>
        <Button variant="contained" onClick={() => getUserList()}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(getListReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{userList}</p>
      <Divider />
      <h2>Enable/Disable User:</h2>
      <div className='item'>
        <TextField value={updateUserId} onChange={(e) => {
          setUpdateUserId(e.target.value);
        }} label="User ID" variant="standard" />
      </div>
      <div className='item'>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Active</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={updateUserOp}
            label="Age"
            onChange={handleUserOp}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
            <MenuItem value={"Test"}>Test</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='item'>
        <Button variant="contained" onClick={() => updateUser()}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(updateUserReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{updUser}</p>
      <Divider />
      <h2>Remove User:</h2>
      <div className='item'>
        <TextField value={delUserId} onChange={(e) => {
          setDelUserId(e.target.value);
        }} label="User ID" variant="standard" />
        <Button style={{ marginLeft: '1rem' }} variant="contained" onClick={() => deleteUser(delUserId)}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(delUserReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{delUser}</p>
      <Divider />
      <h2>Get Group:</h2>
      <div className='item'>
        <TextField value={groupId} onChange={(e) => {
          setGroupId(e.target.value);
        }} label="Group ID" variant="standard" />
        <Button style={{ marginLeft: '1rem' }} variant="contained" onClick={() => getGroup(groupId)}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(getGroupReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{group}</p>
      <Divider />
      <h2>Get Group List:</h2>
      <div className='item'>
        <Button variant="contained" onClick={() => getGroupList()}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(getListReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{groupList}</p>
      <Divider />
      <h2>Add/Remove User from Group:</h2>
      <div className='item'>
        <TextField value={updateGroupId} onChange={(e) => {
          setUpdateGroupId(e.target.value);
        }} label="Role ID" variant="standard" />
      </div>
      <div className='item'>
        <TextField value={updateGroupUserId} onChange={(e) => {
          setUpdateGroupUserId(e.target.value);
        }} label="User ID" variant="standard" />
      </div>
      <div className='item'>
        <TextField value={updateGroupOp} onChange={(e) => {
          setUpdateGroupOp(e.target.value);
        }} label="Operation" variant="standard" />
      </div>
      <div className='item'>
        <Button variant="contained" onClick={() => updateGroup()}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(updateGroupReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{updGroup}</p>
      <Divider />
      <h2>Remove Group:</h2>
      <div className='item'>
        <TextField value={delGroupId} onChange={(e) => {
          setDelGroupId(e.target.value);
        }} label="Group ID" variant="standard" />
        <Button style={{ marginLeft: '1rem' }} variant="contained" onClick={() => deleteGroup(delGroupId)}>Execute</Button>
      </div>
      <h4>Request:</h4>
      <p>{JSON.stringify(delGroupReq, null, "\t")}</p>
      <h4>Response:</h4>
      <p>{delGroup}</p>
    </div>
  );
}

export default App;
